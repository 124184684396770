@media screen and (max-width: 600px) {
    /* #BI {
        min-height: 0px;
        min-width: 0px;
        width: 100%;
        margin: auto;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: .3;
      } */
}

main {
  width: 90vw;
  margin: 0 5vw;
}

#offseason-hr {
  color: black;
  opacity: 1;
  width: 20vw;
  margin: 5vw 35vw;
}

#in-season {
  width: calc(80vw - 20px);
  height: calc((80vw - 20px) * (413/494));
  border: 10px ridge goldenrod;
  margin: 3vw 0;
}

#made-by {
  margin-top: 10vw;
}

