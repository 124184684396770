.behindBlur::after {
    content: '';
    height: 500vh;
    overflow: hidden;
    background-color: grey;
    opacity: .7;
    position: absolute;
    /* position: -webkit-sticky;
    position: sticky; */
    inset: 0;
    /* animation: fadeIn 1s; */
}

/* @keyframes fadeIn {
    0% { opacity: 0; }
    70% { opacity: .7; }
  } */


#recentGoalContainer {
    backdrop-filter: blur(5px);
    position: -webkit-sticky;
    position: fixed;
    /* top: 100px; */
    /* left: 50%; */
    z-index: 5;
    background-color: white;
    /* height: 70vh; */
    width: 60%;
    margin: 0 20%;
    border: 2px solid black;
    animation: flyin .5s ease-out;
    /* margin-top: -50%;
    margin-left: -50%; */
}

@keyframes flyin {
    0% {
        transform: translateY(-200%);
    }

    100% {
        transform: translateY(0%);
    }
}

#recentGoal {
    margin: 5vh auto;
}

#recentGoal h1 {
    font-size: calc((3 - 1) * 1.2vh + 1rem);
    margin: 2vh auto;
}

#recentGoal h3 {
    margin: 3vh auto;
    font-size: calc((2 - 1) * 1.2vh + 1rem);
}

#RGClose {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: red;
    color: white;
    border: 1px solid black;
    border-radius: 2px;
    font-size: 18px;
}

#RGLogo {
    width: 40vh;
    margin: -30px;
}

.assist,
#allAssists {
    display: inline;
    margin: 5px;
    font-size: calc((2 - 1) * 1.2vh + .2rem)
}

@media screen and (min-width: 1500px) {
    #recentGoalContainer {
        width: 900px;
        margin: 0 27%;
        /* left: 0%; */
    }
}

@media screen and (max-width: 600px) {
    #recentGoalContainer {
        width: 80%;
        margin: 0 10%;
        /* left: 0%; */
    }

    #RGLogo {
        width: 70%;
        margin: -30px;
    }

    #recentGoal {
        margin: 40px auto;
    }

    #recentGoal h1 {
        font-size: calc(6vw + 35%);
    }

    #recentGoal h3 {
        margin: 20px auto;
        font-size: calc(4vw + 35%);
    }

    .assist,
    #allAssists {
        font-size: calc(2vw + 35%);
        display: block;
    }
}

