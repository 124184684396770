.div-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    animation: fadeIn 2s;
    max-width: 1300px;
    margin: 0 auto;
}

.divh2 {
    width: 200px;
    margin: 20px auto;
    border: 3px solid black;
    border-radius: 20px;
    padding: 5px;
    font-family: "Oswald";
    font-size: 36px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.button-div {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 18px;
}

.logo {
    width: 200px;
    height: 140px;
    transform: perspective( 170px ) rotateX( 17deg );
}

/* CSS */
.button-82-pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    /* border-radius: 50%; */
}

.button-82-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition:
        transform 600ms cubic-bezier(.3, .7, .4, 1);
    border-radius: 50%;
}

.button-82-edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(to left,
            hsl(340deg 100% 16%) 0%,
            rgb(163, 0, 54) 8%,
            hsl(340deg 100% 32%) 92%,
            hsl(340deg 100% 16%) 100%);
}

.button-82-front {
    display: block;
    position: relative;
    padding: 12px 27px;
    font-size: 1.1rem;
    color: white;
    background: hsl(345deg 100% 47%);
    will-change: transform;
    transform: translateY(-13px);
    border-radius: 50%;
    transition:
        transform 600ms cubic-bezier(.3, .7, .4, 1);
}

.button-82-pushable:hover {
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
    transform: translateY(-20px);
    transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
    transform: translateY(-2px);
    transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
    transform: translateY(4px);
    transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
    transform: translateY(1px);
    transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
    outline: none;
}

@media screen and (max-width: 600px) {
    .logo {
        width: 100px;
        height: 70px
    }

    .button-82-front {
        padding: 5px 15px;
    }

    .divh2 {
        font-size: 20px;
        width: 150px;
    }
}