.oneGame {
    /* border: 2px solid black; */
    /* border-radius: 30px; */
    width: 75%;
    /* height: calc(75vw / 2); */
    min-height: 400px;
    min-width: 800px;
    margin: 20px auto;
    position: relative;
    max-width: 860px;
}

.oneGame::after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('../images/honky-rink.png');
    background-size: 100% 100%;
    border-radius: 35px;
    height: inherit;
    opacity: .4;
    z-index: -1;
}

.infoFlex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
}

.awayTeamInfo,
.homeTeamInfo {
    width: 250px;
    border: 3px solid black;
    background-color: white;
    border-radius: 10px;
}

.awayTeamName,
.homeTeamName {
    margin-bottom: -20px;
    font-size: 24px;
    font-family: "Oswald";
}

/* .awayLogo,
.homeLogo {
    height: 107px;
} */

.awayRecord,
.homeRecord {
    margin: 0 0 20px 0;
}

.gameStatus {
    margin: 10px;
    border: 1px solid black;
    background-color: white;
    padding: 5px;
    font-family: "Oswald";
    font-size: 18px;
}

.gameProgress {
    margin: 5px;
}

.gameScore {
    font-size: 50px;
    margin: 10px auto;
    border: 1px solid black;
    background-color: white;
    border-radius: 20px;
    padding: 5px 15px;
    font-family: "Oswald";
}

.oneTeamGoals {
    margin: 20px 0;
    width: 280px;
}

.oneGoal {
    margin: 2px;
    font-size: 14px;
    height: 15px;
    padding: 5px 0;
}

.oneGoal p {
    margin: 0;
    display: inline;
}

.gameGoals {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 5%;
}

hr {
    margin: 10px;
    color: white;
    opacity: 0;
}

.player-goal-background {
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
}

@media (max-width: 800px) {

    .infoFlex {
        padding-top: 20px;
    }

    .oneGame {
        width: 600px;
        min-width: 100px;
        min-height: 300px;
    }

    .awayTeamInfo,
    .homeTeamInfo {
        width: 100px;
        border: 2px solid black;
    }

    .awayLogo,
    .homeLogo {
        width: 100px;
    }

    .awayTeamInfo,
    .homeTeamInfo {
        width: 200px;
    }

    .awayTeamName,
    .homeTeamName {
        margin-bottom: 0;
        font-size: 10px;
    }

    .gameStatus {
        margin: 5px;
    }

    .gameProgress {
        margin: 0;
    }

    .gameScore {
        font-size: 30px;
    }

    .gameGoals {
        margin: 5px;
        /* height: 130px; */
        overflow: scroll;
    }

    .oneTeamGoals {
        margin: 0px 0;
        width: 240px;
    }

    .oneGoal {
        margin: 2px;
        margin-bottom: 1px;
        font-size: 12px;
        height: 18px;
        padding-bottom: 0px;
    }

    .player-goal-background {
        border: 1px solid black;
    }

    .awayRecord {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .homeRecord {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .recentGoalContainer {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 600px) {
    .nav-div {
        margin-top: 30px;
    }

    .infoFlex {
        padding-top: 3px;
    }

    .oneGame {
        width: 95%;
        min-height: 47.5vw;
        padding: 5px 0;
    }

    .awayTeamInfo,
    .homeTeamInfo {
        width: 100px;
        border: 2px solid black;
        margin-bottom: 10px;
    }

    .awayLogo,
    .homeLogo {
        width: 80px;
        height: 80px;
    }

    .awayTeamName,
    .homeTeamName {
        font-size: 10px;
        margin-bottom: -15px;
    }

    .gameGoals {
        gap: 0;
    }

    .oneTeamGoals {
        height: 67px;
        overflow: scroll;
    }

    .gameStatus {
        margin: 5px;
    }

    .gameProgress {
        margin: 0;
        font-size: 12px;
    }

    .gameScore {
        font-size: 20px;
    }

    .oneTeamGoals {
        margin: 0px 0;
        width: 240px;
    }

    .oneGoal {
        margin: 0 auto;
        margin-bottom: 1px;
        font-size: 8px;
        width: 120px;
        height: 10.5px;
        padding: 3px 0;
    }

    .oneGoal-mobile {
        height: 0px;
        margin: 0;
    }

    .player-goal-background {
        border: 1px solid black;
    }

    .awayRecord,
    .homeRecord {
        font-size: 10px;
        margin-bottom: 5px;
        margin-top: -15px;
    }

}