.App {
  text-align: center;
}

body {
  background-color: rgb(250, 250, 250);
}

#BI {
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

#title {
  font-family: "Faster One", cursive;
  font-size: 3rem;
  margin: 8px auto;
}

.nav-div {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.button-nav-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* border-radius: 50%; */
}

.button-nav-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform 600ms cubic-bezier(.3, .7, .4, 1);
  border-radius: 50%;
}

.button-nav-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(to left,
      hsl(0, 6%, 25%) 0%,
      rgb(94, 91, 92) 8%,
      hsl(338, 7%, 33%) 92%,
      hsl(0, 0%, 24%) 100%);
}

.button-nav-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  font-size: 1.1rem;
  color: white;
  background: hsl(348, 5%, 21%);
  will-change: transform;
  transform: translateY(-13px);
  border-radius: 50%;
  transition:
    transform 600ms cubic-bezier(.3, .7, .4, 1);
}

.button-nav-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-nav-pushable:hover .button-nav-front {
  /* transform: translateY(-20px); */
  transition:
    transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

.button-nav-pushable:active .button-nav-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-nav-pushable:hover .button-nav-shadow {
  /* transform: translateY(4px); */
  transition:
    transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

.button-nav-pushable:active .button-nav-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-nav-pushable:focus:not(:focus-visible) {
  outline: none;
}

@media (max-width: 600px) {
  #title {
    font-size: 35px;
  }

  
}

#soundButton {
  /* position: absolute; */
  /* top: 0px; */
  /* left: 0px; */
  border: 2px solid black;
  font-family: 'Oswald';
  width: 90px;
  height: 80px;
  margin: 20px;
  border-radius: 20px;
  /* padding: 5px; */
  /* font-size: 2rem; */
  /* margin-bottom: -2px; */
  transition: background-color .7s;
  padding-bottom: 5px;
  margin: 0;
  margin-top: 10px;
}

#soundButton:hover {
  background-color: rgb(210, 210, 210);
}

#soundButton:active {
  filter: invert(100%);
}

#soundButton span {
  font-size: 15px;
  vertical-align: top;
  color: black;
}

#soundButton span:first-of-type {
  font-size: 32px;
  margin-bottom: -10px;
  margin-top: -10px;
}

.fixed-button {
  position: fixed;
  top: 0;
  right: 11px;
  z-index: 1;
}

/* @media screen and (max-width: 550px) {
  #soundButton {
    font-size: 1.5rem;
    width: 70px;
    height: 62px;
    border-radius: 12px;
  }

  #soundButton span {
    font-size: .7rem;
    vertical-align: top;
  }

  #soundButton span:first-of-type {
    font-size: 1.5rem;
    margin-bottom: -10px;
  }
} */

/* .activeSoundButton::after {
  content: "   🔊"
} */